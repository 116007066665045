import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ColorLogoMobile from '@dls/assets/images/logo_singtel@2x.png';
import ColorLogoDesktop from '@dls/assets/images/logo_singtel@3x.png';
import WhiteLogo from '@dls/assets/images/logo_singtel_white@3x.png';
import media from '../../helpers/media';
import { isIFrame } from '../../helpers/utils';
import { useRecoilValue } from 'recoil';
import { aemConfigState } from '../../index';

const HeaderWrapper = styled.div`
  ${media.sm`
    margin-top: 24px;
    margin-bottom: 24px;
  `}
  text-align: center;
`;

const LogoImage = styled.img`
  max-width: 120px;
  max-height: 120px;

  &[data-screen='desktop'] {
    display: none;
  }

  ${media.sm`
    &[data-screen='mobile'] {
      display: none;
    }
    &[data-screen='desktop'] {
      display: block;
    }
  `}
`;

export default function Header() {
  let [isIframe, setIsIFrame] = useState(false);
  let aemConfig = useRecoilValue(aemConfigState);

  useEffect(() => {
    setIsIFrame(isIFrame());
  }, []);

  if (isIframe) return null;

  const logo = aemConfig.mode === 'dark' ? WhiteLogo : ColorLogoDesktop;

  return (
    <HeaderWrapper>
      <LogoImage data-screen="desktop" src={logo} alt="Logo" />
      <LogoImage data-screen="mobile" src={ColorLogoMobile} alt="Logo" />
    </HeaderWrapper>
  );
}
