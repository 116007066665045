import styled from 'styled-components';
import media from '../../helpers/media';
import React from 'react';

const Container = styled.div`
  background: white;
  border-radius: 10px;
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  ${media.sm`
    width: 450px;
    padding: 40px;
  `}
  ${media.md`
    width: 460px;
    padding: 40px;
  `}

  .dls-otp-input--containerStyle {
    display: flex;
  }
  .dls-otp-input--inputStyle:last-child {
    margin-right: 0px;
  }
`;

function BoxWrapper(props: React.PropsWithChildren<{}>) {
  return (
    <>
      <Container>{props.children}</Container>
    </>
  );
}

export default BoxWrapper;
